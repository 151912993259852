import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

// Images 
import blogImg from '../assets/images/blog/b4.jpg'
import blogImg2 from '../assets/images/blog/b5.jpg'
import blogImg3 from '../assets/images/blog/b6.jpg'


const CategoryPage = () => {
    return (
        <Layout>
            <Seo title="Category" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="Post Category" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Category" 
            />

            <section className="blog pt-70 pb-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="blog-card">
                                <div className="blog-img-area">
                                    <Link to="/blog-details">
                                        <img 
                                            src={blogImg} 
                                            alt="blog" 
                                        />
                                    </Link>
                                    <div className="blog-img-date">
                                        <span>19</span>
                                        <span>Aug</span>
                                    </div>
                                </div>
                                <div className="blog-text-area">
                                    <div className="blog-date">
                                        <ul>
                                            <li><Link to="/blog">Business</Link> </li>
                                            <li><i className="far fa-comments"></i> 2 Comments</li>
                                        </ul>
                                    </div>
                                    <h4><Link to="/blog-details">L&C Mortgages Appoints Crowd as Its Digital Media Agency</Link></h4>
                                    <Link className="default-button default-button-2"  to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="blog-card">
                                <div className="blog-img-area">
                                    <Link to="/blog-details">
                                        <img 
                                            src={blogImg2} 
                                            alt="blog" 
                                        />
                                    </Link>
                                    <div className="blog-img-date">
                                        <span>18</span>
                                        <span>Aug</span>
                                    </div>
                                </div>
                                <div className="blog-text-area">
                                    <div className="blog-date">
                                        <ul>
                                            <li><Link to="/blog">Business</Link> </li>
                                            <li><i className="far fa-comments"></i> 2 Comments</li>
                                        </ul>
                                    </div>
                                    <h4><Link to="/blog-details">Brave Bison Partners with the BBC’s The Rap Game UK Once Again</Link></h4>
                                    <Link className="default-button default-button-2"  to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="blog-card">
                                <div className="blog-img-area">
                                    <Link to="/blog-details">
                                        <img 
                                            src={blogImg3} 
                                            alt="blog" 
                                        />
                                    </Link>
                                    <div className="blog-img-date">
                                        <span>17</span>
                                        <span>Aug</span>
                                    </div>
                                </div>
                                <div className="blog-text-area">
                                    <div className="blog-date">
                                        <ul>
                                            <li><Link to="/blog">Business</Link> </li>
                                            <li><i className="far fa-comments"></i> 2 Comments</li>
                                        </ul>
                                    </div>
                                    <h4><Link to="/blog-details">Leading Marketing Agency Digital Ethos Announces Exciting Acquisition</Link></h4>
                                    <Link className="default-button default-button-2" to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="paginations mt-30">
                        <ul>
                            <li className="active"><Link to="/category">1</Link></li>
                            <li><Link to="/category">2</Link></li>
                            <li><Link to="/category">3</Link></li>
                            <li><Link to="/category"><i className="fas fa-angle-right"></i></Link></li>
                        </ul>
                    </div> */}
                </div>
            </section>

            <Footer/>

            <Copyright />
        </Layout>
    )
}

export default CategoryPage
